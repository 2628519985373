export const USER_CONNECTED = "USER_CONNECTED";
export const LOGOUT = "LOGOUT";
export const PRIVATE_MESSAGE = "PRIVATE_MESSAGE";
export const TYPING = "TYPING";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const MESSAGE_SENT = "MESSAGE_SENT";

// {
//   COMMUNITY_CHAT: "COMMUNITY_CHAT",
//   USER_CONNECTED: "USER_CONNECTED",
//   MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
//   MESSAGE_SENT: "MESSAGE_SENT",
//   USER_DISCONNECTED: "USER_DISCONNECTED",
//   TYPING: "TYPING",
//   VERIFY_USER: "VERIFY_USER",
//   LOGOUT: "LOGOUT",
//   PRIVATE_MESSAGE: "PRIVATE_MESSAGE",
//   NEW_CHAT_USER: "NEW_CHAT_USER",
// };
